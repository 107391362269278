<template>
  <div class="full-area" :class="{'feedback': feedback}">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="emitNext()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle"
    >{{data.fields.questionSubtitle}}</h2>

    <header class="question-header image-question contain-image"
      v-if="data.fields.questionImage && data.fields.questionImage.fields"
    >
      <div
        class="img"
        :style="{'backgroundImage': `url(${data.fields.questionImage.fields.file.url})`}"
      ></div>
    </header>
    <content class="content delay-entry" :class="{'is-vertical': data.fields.isVertical, 'currency-wrapper': data.fields.isCurrency}">
      <div class="slider-wrapper">
        <h2
          :key="numbersToTextScale(sliderValue)"
          class="slider-value is-currency"
          :class="{'hide': sliderValue === ((form.to + form.from) / 2 + 0.1), 'text-scale': data.fields.useTextScale}"
          v-if="data.fields.isCurrency"
        >
          ${{data.fields.textScale ? data.fields.textScale[sliderValue - 1] : numbersToTextScale(sliderValue)}}
        </h2>
        <div
          class="slider-marks-wrapper"
          v-if="data.fields.showNumbers"
        >
          <div class="slider-mark" v-for="b in (data.fields.to - data.fields.from + 1)">
            {{b}}
          </div>
        </div>
        <div class="knob-wrapper" v-if="data.fields.showPieChart">
          <knob-control
            :min="data.fields.from"
            :max="dynamicTo"
            :stroke-width="20"
            :size="270"
            :animated="true"
            :stepSize="data.fields.step"
            primary-color="#5C80BC"
            secondary-color="#E4E7ED"
            text-color="#5C80BC"
            :value-display-function="valueDisplayFunction"
            v-model="sliderValue"
          ></knob-control>
        </div>
        <el-slider
          :min="form.from"
          :max="dynamicTo"
          :show-tooltip="false"
          :vertical="data.fields.isVertical"
          :step="data.fields.step"
          :height="data.fields.questionImage || data.fields.isCurrency ? '35vh' : '55vh'"
          v-model="sliderValue"
        ></el-slider>
        <div class="submit-button-wrapper" v-if="!data.fields.isCurrency && data.fields.isVertical">
          <h2
            :key="numbersToTextScale(sliderValue)"
            class="slider-value"
            :class="{'hide': sliderValue === ((form.to + form.from) / 2 + 0.1), 'text-scale': data.fields.useTextScale}"
          >
            {{data.fields.textScale ? data.fields.textScale[sliderValue - 1] : numbersToTextScale(sliderValue)}}
          </h2>
        </div>
      </div>
      <div class="slider-labels" v-if="data.fields.useTextScale">
        <div
          class="label-item"
          v-for="text in data.fields.textScale"
          :key="text"
        >
          {{text}}
        </div>
      </div>
    </content>

    <SkipQuestionButton
      :location="'locate-bottom'"
      v-on:skip="selectThis('S99')"
      :is-mandatory="data.fields.isMandatory"
      :disable-submit-button="!showSubmitButton"
      :show-submit="showSubmitButton"
      v-on:submit="sendUsingButton()"
    ></SkipQuestionButton>
  </div>
</template>

<script>
import Vue from 'vue'
import KnobControl from 'vue-knob-control'

Vue.use(KnobControl) 

export default {
  name: 'Slider',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton'),
    Icons: () => import('Components/base/Icons'),
    KnobControl
  },

  data () {
    return {
      untouched: true,
      feedback: '',
      locked: false,
      showSubmitButton: false,
      sliderValue: !isNaN(parseInt(this.data.fields.initialValue, 10)) ? parseInt(this.data.fields.initialValue, 10) : (this.data.fields.to + this.data.fields.from) / 2 + 0.1,
      form: {
        type: `slider`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.title,
        numberOfOptions: (this.data.fields.to - this.data.fields.from) / (this.data.fields.step || 1),
        id: this.data.sys.id,
        from: this.data.fields.from,
        to: this.data.fields.to,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      },
      timer: null,
      timerSubmit: null
    }
  },

  computed: {
    marks () {
      if (this.data.fields.showNumbers) {
        var obj = {}
        for (var i = this.data.fields.from; i <= this.data.fields.to; i++) {
          obj[i] = i.toString()
        }
        return obj
      } else {
        return {}
      }
    },
    dynamicTo () {
      if (this.data.fields.sliderToGlobalVar) {
        var c = this.data.fields.sliderToGlobalVar.replace(/var.x/g, this.$store.state.globalVars.x)
        return eval(c)
      }
      return parseInt(this.data.fields.to, 10)
    }
  },

  methods: {
    asHourMinute (value) {
      var h = Math.floor(value / 60)
      var m = value % 60
      return `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}`
    },
    valueDisplayFunction (value) {
      if (this.untouched) return ''
      if (this.data.fields.isPercentage) {
        return `${value}%`
      }
      if (this.data.fields.isTime) {
        return `${this.asHourMinute(value)}`
      }
      if (this.data.fields.isCurrency) {
        return ``
      }
      return value
    },
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    emitNext () {
      if (this.data.fields.storeResponseLocally) {
        this.$store.commit('STORE_LOCAL_RESPONSE', { key: this.form.identifier, value: this.form.selected[0] })
      }
      this.$emit('next', this.form)
    },
    sendUsingButton () {
      this.selectThis (this.sliderValue)
    },
    selectThis (item) {
      if (this.locked) {
        return
      }
      this.form.selected = [item]
      this.form.timestamps.valid = new Date()
      this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      if (this.data.fields.globalVariable) {
        this.$store.commit('SAVE_GLOBAL_VAR', { field: this.data.fields.globalVariable, value: item })        
      }
      setTimeout(() => {
        this.next()
      }, 50)
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value >= this.form.from && value <= this.form.to && Number.isInteger(value)) {
        return true
      } else {
        return false
      }
    },
    numbersToTextScale (value) {
      if (!this.data.fields.useTextScale) {
        if (this.data.fields.isCurrency) {
          return Number(value.toFixed(1)).toLocaleString()
        }
        return value
      }
      if (this.data.fields.from === 1 && this.data.fields.to === 4) {
        switch (value) {
          case 1:
            return 'Nada'
          case 2:
            return 'Poco'
          case 3:
            return 'Algo'
          case 4:
            return 'Mucho'
          default:
            return 'Mueve la bolita'
        }
      }
      if (this.data.fields.from === 1 && this.data.fields.to === 5) {
        switch (value) {
          case 1:
            return 'Totalmente en desacuerdo'
          case 2:
            return 'Parcialmente en desacuerdo'
          case 3:
            return 'Neutral'
          case 4:
            return 'Parcialmente de acuerdo'
          case 5:
            return 'Totalmente de acuerdo'
          default:
            return 'Mueve la bolita'
        }
      }
    },
    calculateScores () {
      if (this.data.fields.to - this.data.fields.from > 100) {
        this.emitNext()
        return
      }
      this.locked = true
      var options = []
      for (var i = this.data.fields.from; i <= this.data.fields.to; i++) {
        options.push(i)
      }
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula,
        selected: this.form.selected,
        options: options,
        maxAnswers: 1,
        skip: this.data.fields.skip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.feedback = scores.feedback
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },

  mounted () {
    this.form.to = this.dynamicTo
    if (this.data.fields.saveToDimension) {
      this.form.saveToDimension = this.data.fields.saveToDimension
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal) && Number.isInteger(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    },
    'sliderValue': function (newVal, oldVal) {
      this.untouched = false
      clearTimeout(this.timerSubmit)
      this.timerSubmit = setTimeout(() => {
        if (this.data.fields.showPieChart) {
          this.showSubmitButton = true
        } else {
          this.sendUsingButton()
        }
      }, 1100)
    }
  }
}
</script>

<style lang="scss">
.content {
  &.is-vertical {
    .el-slider__runway {
      margin: auto;
    }
  }
}
.knob-control__text-display {
  font-size: 17px !important;
}
</style>

<style scoped lang="scss">
.knob-wrapper {
  margin-bottom: 30px;
  @include breakpointHeight($square) {  
    transform: scale(0.8);
    margin-bottom: 0;
  }
}
.slider-marks-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 109%;
  margin-left: -4%;
}
.slider-mark {
  text-align: center;
  min-width: 30px;
}
.slider-labels {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em;
  line-height: 1.1;
  max-width: 500px;

  @include breakpoint($desktop) {
    width: 360px;
  }
  @include breakpoint($air) {
    width: 380px;
  }
  @include breakpoint($fullhd) {
    width: 460px;
  }
  .label-item {
    max-width: 37vw;
    font-size: 18px;
    text-align: left;
    &:last-child {
      text-align: right;
    }
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;
    @include breakpointHeight($square) {
      line-height: 1;
      font-size: 15px !important;
    }
  }
}
.question-header.align-bottom {
  max-height: 20vh;
}
.content {
  flex-direction: column;
  margin-bottom: 30px !important;

  &.is-vertical {
    flex-direction: row-reverse;

    .slider-wrapper {
      width: 100%;
      margin: auto;
    }
    .el-slider__runway {
      margin: auto;
    }
  }
}

.slider-wrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;
  max-width: 500px;

  @include breakpoint($tablet) {
    width: 90%;
  }
  @include breakpoint($air) {
    width: 90%;
  }

  .slider-value {
    position: fixed;
    right: 25%;
  }
}

.slider-value {
  margin-bottom: 0;
  font-size: 2.5em;
  font-weight: bold;
  color: $color-emphasis-blue;

  @include breakpoint($tablet) {
    line-height: 80px;
  }

  &.text-scale {
    font-weight: bold;
    font-size: 1.3em;
    line-height: 25px;
    @include breakpoint($tablet) {
      font-size: 1.2em;
      line-height: 30px;
    }
  }
  &.is-currency {
    position: static;
    margin-bottom: 1em;
  }
}
.hide {
  opacity: 0.0001;
}
.submit-button-wrapper {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  right: 10%;
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .slider-value {
    position: static;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
  .submit-button {
    @include breakpoint($tablet) {
      transform: scale(1.5);
    }    
  }
}

</style>
